<template>
  <svg
    width="12px"
    height="23px"
    viewBox="0 0 12 23"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="transform: rotate(90deg)"
  >
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="square"
    >
      <g
        transform="translate(-333.000000, -571.000000)"
        stroke="#FFFFFF"
        stroke-width="1.179648"
      >
        <g transform="translate(-3.000000, 330.554113)">
          <g transform="translate(336.702273, 241.645359)">
            <line
              x1="0.288032735"
              y1="0.289049472"
              x2="10.0811457"
              y2="10.6948305"
            />
            <line
              x1="0.288032735"
              y1="20.5225125"
              x2="10.0811457"
              y2="10.6948305"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
